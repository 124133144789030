// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
// @import 'modules/menu';
@import 'modules/menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-intro {
    .section-title {
        background: #182548;
        border-top: 20px solid #191827;
        border-bottom: 20px solid #191827;
        height: 100px;
        position: relative;
        .title {
            background: #182548;
            text-align: center;
            height: 100px;
            position: absolute;
            top: -20px;
            left: 24%;
            z-index: 1;
            width: 55%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #fff;
                font-style: italic;
                line-height: 1.3;
            }
        }
    }
    .section-left-right {
        display: flex;
        background: #191827;
        justify-content: center;
        padding: 50px 0;
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: #fff;
            margin: 0;
            line-height: 1.3;
        }
        .left-side {
            width: 35%;
            height: 130px;
            padding-right: 50px;
        }
        .right-side {
            width: 35%;
            border-left: 2px solid #fff;
            padding-left: 50px;
        }
    }
}

#section-services {
    .section-title {
        background: #182548;
        height: 65px;
        position: relative;
        .title {
            position: absolute;
            height: 100px;
            background: #182548;
            width: 55%;
            top: 50%;
            left: 24%;
            z-index: 1;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #fff;
                font-style: italic;
            }
        }
    }

    .background {
        background: url("../images/accueil_section01_pho_bg.jpg") no-repeat;
        min-height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding: 0;
        margin: 0;
        .section-txt {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 50%;
            background: rgba(0, 0, 0, 0.6);
            transform: translateX(-100%);
            opacity: 1;
            transition: all .5s ease-in;
            .text {
                width: 80%;
                margin: 10% auto 0 auto;
                padding-left: 15%;
                p {
                    font-weight: normal;
                    font-size: $font-size-22;
                    color: #fff;
                    margin: 0;
                    line-height: 1.5;
                }
                p.last {
                    padding-top: 30px;
                }
            }
        }
        &.isCompletelyVisible {
              .section-txt {
                transform: translateX(0);
                transition: .5s;
              }
        }
    }
}

#section-icon {
    background: #191827;
    padding: 75px 15%;
    h4 {
        font-weight: 600;
        font-size: $font-size-24;
        color: #fff;
        padding-bottom: 35px;
    }
    .section-service {
        display: flex;
        flex-wrap: wrap;
        .bloc {
            width: 33.3333%;
            padding: 20px;
            .img-responsive {
                margin: auto;
                padding-bottom: 15px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-24;
                color: #fff;
                margin: 0;
                line-height: 1.5;
                font-style: italic;
                text-align: center;
            }
        }
    }
}

#section-parallax {
    background: url(../images/accueil_section04_parallax_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
        font-weight: bold;
        font-size: $font-size-28;
        color: #fff;
        font-style: italic;
        line-height: 1.7;
        text-align: center;
    }
}

#section-text {
    .section-title {
        background: #182548;
        border-top: 20px solid #191827;
        border-bottom: 20px solid #191827;
        height: 100px;
        position: relative;
        .title {
            background: #182548;
            text-align: center;
            height: 100px;
            position: absolute;
            top: -20px;
            left: 24%;
            z-index: 1;
            width: 55%;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #fff;
                font-style: italic;
                line-height: 1.3;
            }
        }
    }
    .section-list {
        background: #191827;
        display: flex;
        justify-content: space-evenly;
        padding: 50px 5%;
        ul {
            list-style-image: url(../images/accueil_section05_point_bleu.jpg);
            padding-left: 20px;
            li {
                font-weight: 600;
                font-size: $font-size-24;
                color: #fff;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 15px;
                text-transform: initial;
            }
        }
    }
}

#section-bursts {
    display: flex;
    a.burst {
        display: flex;
        width: 33.3333%;
        .text {
            background: #182548;
            width: 53%;
            padding: 50px 5%;
            transition: 0.3s;
            h3 {
                font-weight: bold;
                font-size: $font-size-28;
                color: #fff;
                font-style: italic;
                transition: 0.3s;
            }
        }
        .img {
            .img-responsive.hover {
                display: none;
                transition: 0.3s;
            }
        }
        &:hover {
            .text {
                background: #3e60b9;
                transition: 0.3s;
                h3 {
                    color: #191827;
                    transition: 0.3s;
                }
            }
            .img {
                .img-responsive.hover {
                    display: block;
                    transition: 0.3s;
                }
                .img-responsive.defaut {
                    display: none;
                    transition: 0.3s;
                }
            }
        }
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-conception {
    display: flex;
    .left-side {
        width: 50%;
        background: url("../images/produits_section01_pho_bg.jpg") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 2%;
        padding-right: 5%;
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: #fff;
            margin: 0;
            line-height: 1.5;
            width: 71%;
        }
    }
    .right-side {
        width: 50%;
        background: #0e162b;
        padding: 35px;
        h3 {
            font-weight: 600;
            font-size: $font-size-28;
            color: #fff;
            text-transform: initial;
        }
        h3.last {
            padding-top: 25px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: #fff;
            margin: 0;
            line-height: 1.5;
            width: 70%;
        }
        p.first {
            padding: 25px 0px;
        }
    }
}

#section-text.produits {
    .section-photo {
        display: flex;
        flex-wrap: wrap;
        .photo {
            width: 33.3333%;
            .title {
                background: #182548;
                padding: 10px 0;
                border: 1px solid #888888;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-28;
                    color: #fff;
                    font-style: italic;
                    text-align: center;
                }
            }
        }
    }
}

#section-list {
    background: #191827;
    padding: 75px 10%;
    p {
        font-weight: 600;
        font-size: $font-size-22;
        color: #fff;
        margin: 0;
        line-height: 1.5;
        padding-bottom: 50px;
    }
    .list {
        display: flex;
        ul {
            list-style-image: url(../images/accueil_section05_point_bleu.jpg);
            padding-left: 20px;
            li {
                font-weight: 600;
                font-size: $font-size-24;
                color: #fff;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 15px;
                text-transform: initial;
            }
        }
        .left-side {
            width: 54%;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-services.page-services {
    background: #191827;
    padding: 75px 10%;
    p {
        font-weight: 600;
        font-size: $font-size-22;
        color: #fff;
        margin: 0;
        line-height: 1.5;
    }
    .section-img {
        display: flex;
        padding-top: 35px;
        .img {
            width: 55%;
        }
    }
}

#section-background {
    background: url("../images/services_section02_bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 570px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
        font-weight: bold;
        font-size: $font-size-28;
        color: #fff;
        font-style: italic;
        text-align: center;
        line-height: 1.5;
    }
}

#section-txt-btn {
    background: #191827;
    padding: 75px 10%;
    .bloc {
        h3 {
            font-weight: 600;
            font-size: $font-size-28;
            color: #fff;
            text-transform: initial;
        }
        .txt-btn {
            display: flex;
            align-items: center;
            .txt {
                width: 50%;
                margin-right: 3%;
                p {
                    font-weight: 600;
                    font-size: $font-size-22;
                    color: #fff;
                    margin: 0;
                    line-height: 1.5;
                    padding: 50px 0;
                }
            }
            a.bouton {
                background: #fff;
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 115px;
                transition: 0.3s;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-28;
                    color: #182548;
                }
                &:hover {
                    background: #182548;
                    transition: 0.3s;
                    h4 {
                        color: #fff;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* MARQUES
*******************************************************************************/

#section-marques {
    background: #191827;
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bloc {
        padding: 35px 0;
        h3 {
            font-weight: 600;
            font-size: $font-size-28;
            color: #fff;
            text-transform: initial;
            padding-bottom: 35px;
            padding-left: 5%;
        }
        .left-right {
            display: flex;
            justify-content: center;
            p {
                font-weight: normal;
                font-size: $font-size-22;
                color: #fff;
                margin: 0;
                line-height: 1.5;
                border-right: 1px solid #fff;
                height: 150px;
            }
            p.last {
                border-right: none;
            }
            .img-responsive {
                padding-top: 45px;
            }
            .left-side {
                width: 45%;
            }
            .right-side {
                width: 45%;
                padding-left: 45px;
            }
        }
    }
}



/*******************************************************************************
* FILIALES
*******************************************************************************/

#section-filiales {
    background: #191827;
    padding: 50px 10% 75px 10%;
    .intro {
        display: flex;
        padding-bottom: 35px;
        .left-side {
            width: 39%;
            padding-top: 2%;
            p {
                font-weight: normal;
                font-size: $font-size-22;
                color: #fff;
                margin: 0;
            }
        }
    }
    .bloc {
        display: flex;
        .logo {
            width: 45%;
        }
        .logo.big {
            width: 39.5%;
        }
        .text {
            h3 {
                font-weight: 600;
                font-size: $font-size-28;
                color: #fff;
                text-transform: initial;
            }
            p {
                font-weight: normal;
                font-size: $font-size-22;
                color: #fff;
                margin: 0;
                line-height: 1.5;
            }
        }
        .list {
            display: flex;
            padding-left: 3%;
                ul {
                list-style-image: url(../images/accueil_section05_point_bleu.jpg);
                padding: 15px 0 15px 5%;
                li {
                    font-weight: 600;
                    font-size: $font-size-24;
                    color: #fff;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    padding-left: 15px;
                    text-transform: initial;
                }
            }
            .left-side {
                width: 35%;
            }
            .right-side {
                width: 65%;
            }
        }
    }
    .bloc.middle {
        padding: 65px 0;
    }
}

/*******************************************************************************
* EMPLOI
*******************************************************************************/

#section-list.emploi {
    p {
        font-weight: normal;
    }
    h4 {
        font-weight: 600;
        font-size: $font-size-28;
        color: #fff;
        padding-bottom: 20px;
    }
    .list {
        justify-content: center;
        .left-side {
            width: 45%;
        }
    }
}

#section-emploi {
    background: #191827;
    padding: 0 10% 50px 10%;
    h4 {
        font-weight: 600;
        font-size: $font-size-28;
        color: #fff;
        padding-bottom: 20px;
    }
    .emploi {
        .bloc {
            h5 {
                font-weight: 600;
                font-size: $font-size-24;
                color: #fff;
                padding-bottom: 5px;
            }
            h4 {
                font-weight: bold;
                font-size: $font-size-22;
            }
            p, li {
                font-weight: normal;
                font-size: $font-size-22;
                color: #fff;
                line-height: 1.5;
            }
            ul {
                padding-left: 30px;
                padding-bottom: 25px;
                li {
                    padding-bottom: 5px;
                    padding-left: 15px;
                    text-transform: initial;
                }
            }
            .bouton {
                width: 20%;
                background: #182548;
                text-align: center;
                padding: 10px;
                margin-bottom: 70px;
                a.btnPostulez {
                    font-weight: bold;
                    font-size: $font-size-28;
                    color: #fff;
                }
                &:hover {
                    a.btnPostulez {
                        color: #000;
                    }
                }
            }
        }
    }
}

#section-formulaire {
    background: #191827;
    padding: 1px 0 50px 0;
    form label {
        width: 40%;
    }
    textarea#message {
        margin-left: -8%;
    }
    #right {
        display: flex;
        align-items: center;
        margin-left: 17.5%;
        button#submit {
            margin-left: 0;
            font-weight: bold;
            font-size: $font-size-26;
            color: #fff;
        }
        label.image {
            background: #535353;
            text-align: center;
            width: 290px;
            padding: 18.5px;
            margin-bottom: 15px;
            font-weight: bold;
            font-size: $font-size-26;
            color: #fff;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

.section-projets {
    background: #191827;
    padding: 50px 0;
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    background: #191827;
    padding: 50px 10% 75px 10%;
    .intro {
        padding-bottom: 50px;
        p {
            font-weight: normal;
            font-size: $font-size-22;
            color: #fff;
            margin: 0;
            line-height: 1.5;
        }
    }
    .info-map {
        display: flex;
        align-items: center;
        .left-side {
            width: 45%;
            .info {
                padding-bottom: 75px;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-24;
                    color: #fff;
                    line-height: 1.5;
                }
            }
            .tel {
                display: flex;
                h3 {
                    font-weight: 600;
                    font-size: $font-size-28;
                    color: #fff;
                    text-transform: initial;
                    line-height: 1.5;
                }
                .txt {
                    width: 40%;
                }
            }
        }
        .right-side {
            width: 55%;
        }
    }
    .poste {
        display: flex;
        flex-wrap: wrap;
        padding-top: 100px;
        .bloc {
            width: 33.3333%;
            padding: 25px 0;
            h3 {
                font-weight: normal;
                font-size: $font-size-24;
                color: #fff;
            }
            p {
                font-weight: 600;
                font-size: $font-size-24;
                color: #fff;
                margin: 0;
                line-height: 1.5;
            }
        }
    }
}

#section-services.page-coordonnees {
    background: #2b4381;
    .section-title {
        background: #182548;
        height: 65px;
        position: relative;
    }
    .heures-form {
        display: flex;
        padding: 100px 10% 50px 10%;
        .left-side {
            width: 50%;
            p {
                font-weight: 600;
                font-size: 1.2rem;
                color: #fff;
                margin: 0;
                line-height: 1.5;
                padding-bottom: 15px;
            }
            .heures {
                display: flex;
                .jour {
                    width: 20%;
                }
                p {
                    font-weight: normal;
                    font-size: $font-size-24;
                    color: #fff;
                    margin: 0;
                    line-height: 1.5;
                    padding: 0;
                }
            }
        }
        .formulaire {
            width: 50%;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1810px) {

nav.module-menu .menu-navbar .wrapper.right.left .main-menu, nav.module-menu .menu-navbar .wrapper.left.left .main-menu {
    margin-left: 65%;
}
#section-marques .bloc .left-right p {
    padding-right: 20px;
}
#section-marques .bloc .left-right .img-responsive {
    padding-top: 50px;
}
}

@media screen and (max-width: 1700px) {

nav.module-menu .menu-navbar .wrapper.right.left .main-menu, nav.module-menu .menu-navbar .wrapper.left.left .main-menu {
    margin-left: 70%;
}
#section-services.page-coordonnees .heures-form {
    padding: 100px 5% 50px 5%;
}
#section-coordonnees {
    padding: 50px 5% 75px 5%;
}
#section-list {
    padding: 75px 5%;
}
#section-emploi {
    padding: 0 5% 50px 5%;
}
}

@media screen and (max-width: 1650px) {

#section-txt-btn {
    padding: 75px 5%;
}
#section-marques {
    padding: 50px 5%;
}
#section-filiales {
    padding: 50px 5% 75px 5%;
}
#section-services.page-coordonnees {
    padding: 0;
}
}

@media screen and (max-width: 1600px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.left .main-menu > ul li div {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu {
    margin-right: -11%;
}
}

@media screen and (max-width: 1555px) {

#section-intro .section-title .title h3, #section-services .section-title .title h3, #section-text .section-title .title h3{
    font-size: 1.2rem;
}
#section-bursts a.burst .text h3 {
    font-size: 1.3rem;
}
nav.module-menu .menu-navbar .wrapper.right.left .main-menu, nav.module-menu .menu-navbar .wrapper.left.left .main-menu {
    margin-left: 65%;
}
#section-list {
    padding: 75px 5%;
}
#section-conception .right-side p {
    width: 85%;
}
#section-conception .left-side p {
    width: 75%;
}
#section-conception .left-side {
    padding-bottom: 3%;
    padding-right: 5%;
}
#section-services p br {
    display: none;
}
#section-services .section-img .img {
    width: 50%;
    margin-right: 2%;
}
#section-services .section-img {
    justify-content: center;
}
#section-marques .bloc .left-right .img-responsive {
    padding-top: 80px;
}
nav.module-menu .menu-navbar .wrapper.right.right .main-menu {
    margin-right: -8%;
}
}

@media screen and (max-width: 1480px) {

}

@media screen and (max-width: 1460px) {

#section-marques .bloc .left-right p br {
    display: none;
}
}

@media screen and (max-width: 1455px) {

#section-filiales .bloc .logo {
    width: 55%;
}
#section-filiales .bloc .logo.big {
    width: 48.5%;
}
#section-coordonnees .info-map .left-side .tel .txt {
    width: 50%;
}
#section-coordonnees .info-map .left-side .tel h3 {
    font-size: 1.3rem;
}
#section-services.page-coordonnees .heures-form .heures .jour {
    width: 25%;
}
.btn {
    margin-left: 40%;
}
}

@media screen and (max-width: 1435px) {

#section-bursts a.burst .text h3 {
    font-size: 1.2rem;
}
}

@media screen and (max-width: 1355px) {

#section-intro .section-title .title h3, #section-services .section-title .title h3, #section-text .section-title .title h3 {
    font-size: 1.1rem;
}
#section-bursts a.burst .text h3 {
    font-size: 1.1rem;
}
#section-list .list ul li {
    font-size: 1.1rem;
}
#section-conception .left-side p {
    width: 85%;
}
#section-conception .left-side {
    padding-bottom: 5%;
    padding-right: 5%;
}
#section-services.page-coordonnees .heures-form .formulaire form label {
    width: 45%;
}
}

@media screen and (max-width: 1320px) {

nav.module-menu .menu-navbar .wrapper.right.right .main-menu {
    margin-right: 0%;
}
nav.module-menu .menu-navbar .wrapper.left.left .main-menu {
    margin-left: 0%;
}
}

@media screen and (max-width: $size-md-max) {

#section-intro .section-left-right .right-side {
    width: 37%;
    padding-left: 100px;
}
#section-icon {
    padding: 75px 10%;
}
#section-parallax, #section-background {
    min-height: 450px;
}
#section-txt-btn .bloc .txt-btn .txt {
    margin-right: 10%;
}
#section-marques .bloc .left-right .img-responsive {
    padding-top: 50px;
}
#section-filiales .bloc .logo {
    width: 40%;
    margin-right: 5%;
}
#section-filiales .bloc .logo.big {
    width: 35.5%;
}
#section-coordonnees .info-map .left-side .info {
    padding-bottom: 45px;
}
#section-services.page-coordonnees .heures-form .formulaire .btn {
    margin-left: 47%;
}
}

@media screen and (max-width: 1024px) {

#section-bursts {
    flex-direction: column;
}
#section-bursts a.burst {
    width: 100%;
    border-bottom: 2px solid #191827;
}
#section-bursts a.burst:last-child {
    border-bottom: none;
}
#section-bursts a.burst .text {
    width: 71%;
}
#section-bursts a.burst .text h3 {
    font-size: 1.3rem;
}
#section-icon {
    padding: 50px 3%;
}
#section-intro .section-title .title, #section-services .section-title .title, #section-text .section-title .title {
    left: 19%;
    width: 65%;
}
#section-conception .right-side p {
    width: 100%;
}
#section-conception .left-side {
    padding-bottom: 7%;
    padding-right: 0%;
}
#section-conception .left-side p {
    width: 95%;
}
#section-list .list .left-side {
    width: 60%;
    margin-right: 5%;
}
#section-marques .bloc .left-right {
    flex-direction: column;
}
#section-marques .bloc .left-right .right-side {
    width: 100%;
    padding-left: 0;
}
#section-marques .bloc .left-right .left-side {
    width: 100%;
}
#section-marques .bloc .left-right p {
    padding-right: 0;
    border-right: none;
    height: initial;
}
#section-marques .bloc h3 {
    padding-left: 0%;
}
#section-marques .bloc .left-right p.last {
    padding-top: 25px;
}
#section-marques .bloc .left-right .img-responsive {
    padding-top: 30px;
}
#section-filiales .intro .left-side {
    width: 32%;
}
#section-filiales .intro .left-side p {
    line-height: 1.3;
}
#section-services.page-coordonnees .heures-form .formulaire .btn {
    margin-left: 37%;
}
#section-formulaire #right {
    margin-left: 17%;
}
#section-services.page-coordonnees .heures-form .left-side .heures .jour {
    width: 30%;
}
}

@media screen and (max-width: $size-sm-max) {

#section-intro .section-title .title, #section-services .section-title .title, #section-text .section-title .title {
    left: 13%;
    width: 75%;
}
#section-parallax {
    min-height: 325px;
}
#section-parallax h3 br {
    display: none;
}
#section-conception .left-side {
    background-position: center;
}
#section-text.produits .section-photo .photo {
    width: 50%;
}
#section-background h3 br {
    display: none;
}
#section-parallax, #section-background {
    padding: 0 2%;
}
#section-txt-btn .bloc .txt-btn {
    flex-direction: column;
    align-items: baseline;
    padding-bottom: 25px;
}
#section-txt-btn .bloc .txt-btn .txt {
    width: 100%;
    margin-right: 0;
}
#section-txt-btn .bloc .txt-btn a.bouton {
    width: 55%;
}
#section-txt-btn .bloc .txt-btn .txt p {
    padding: 25px 0;
}
#section-txt-btn {
    padding: 50px 5%;
}
#section-marques {
    padding: 25px 5%;
}
#section-filiales .intro {
    flex-direction: column;
}
#section-filiales .intro .left-side {
    width: 100%;
    padding-top: 0;
    padding-bottom: 25px;
    text-align: center;
}
#section-filiales .intro .right-side .img-responsive {
    margin: auto;
}
#section-filiales .bloc {
    flex-direction: column;
}
#section-filiales .bloc .logo {
    width: 100%;
    padding-bottom: 15px;
    margin-right: 0;
}
#section-filiales .bloc .logo.big {
    width: 100%;
}
#section-services.page-coordonnees .heures-form {
    padding: 50px 5%;
    flex-direction: column;
}
#section-services.page-coordonnees .heures-form .heures {
    width: 100%;
    padding-bottom: 40px;
}
#section-services.page-coordonnees .heures-form .formulaire {
    width: 100%;
}
#section-services.page-coordonnees .heures-form .formulaire .btn {
    margin-left: 60%;
}
#section-coordonnees .poste .bloc {
    width: 50%;
}
#section-coordonnees .info-map {
    align-items: initial;
    flex-direction: column;
}
#section-coordonnees .info-map .left-side {
    width: 100%;
    padding-bottom: 35px;
}
#section-coordonnees .info-map .right-side {
    width: 100%;
}
#section-coordonnees .poste {
    padding-top: 50px;
}
#section-coordonnees .info-map .left-side .tel .txt {
    width: 30%;
}
div#section-realisations .section-projets .col-sm-4 {
    width: 50%;
}
#section-formulaire form label {
    width: 50%;
}
#section-emploi .emploi .bloc .bouton {
    width: 30%;
}
#section-formulaire textarea#message {
    margin-left: -11%;
}
#section-formulaire #right {
    margin-left: 19%;
    flex-direction: column;
    align-items: baseline;
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar.nav-visible .wrapper.center {
    margin-left: -17%;
}
nav.module-menu .menu-navbar {
    height: 82px;
}
#section-intro .section-title .title, #section-services .section-title .title, #section-text .section-title .title, #section-intro .section-title {
    width: 100%;
    position: initial;
    height: 130px;
}
#section-intro .section-title, #section-text .section-title {
    border: none;
}
#section-intro .section-title .title h3, #section-services .section-title .title h3, #section-text .section-title .title h3 {
    font-size: 1.3rem;
}
#section-intro .section-left-right {
    flex-direction: column;
    padding: 50px 3%;
}
#section-intro .section-left-right .right-side {
    width: 100%;
    padding-left: 0;
    border: none;
}
#section-icon .section-service .bloc {
    width: 50%;
    padding: 15px;
}
#section-icon .section-service .bloc p {
    font-size: 1.1rem;
}
#section-parallax {
    text-align: center;
}
#section-text .section-list {
    padding: 25px 5%;
    flex-direction: column;
}
#section-text .section-list .right-side {
    margin-top: -10px;
}
#section-bursts a.burst .text h3 {
    font-size: 1rem;
}
#section-text .section-title .title, #section-intro .section-title .title, #section-services .section-title .title {
    height: 100%;
}
#section-parallax h3 {
    font-size: 1.3rem;
}
#section-icon h4 {
    text-align: center;
}
#section-intro .section-left-right .left-side {
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-bottom: 25px;
}
#section-services .section-title {
    height: 100%;
    padding: 15px 0;
}
#section-services .section-title .title {
    transform: none;
}
#section-intro .section-title .title h3, #section-services .section-title .title h3, #section-text .section-title .title h3 {
    font-size: 1.2rem;
}
#section-services .background .section-txt .text {
    width: 100%;
    padding: 0 5%;
}
#section-conception {
    flex-direction: column;
}
#section-conception .left-side {
    width: 100%;
    min-height: 400px;
    padding: 100px 5%;
    flex-direction: column;
    justify-content: center;
}
#section-conception .right-side {
    width: 100%;
}
#section-text.produits .section-photo .photo {
    width: 100%;
}
#section-list .list {
    flex-direction: column;
}
#section-list .list .left-side {
    width: 100%;
    margin-right: 0;
}
#section-list {
    padding: 50px 5%;
}
#section-conception .right-side {
    padding: 35px 5%;
}
#section-txt-btn .bloc .txt-btn a.bouton {
    width: 85%;
}
#section-parallax, #section-background {
    min-height: 350px;
}
#section-services .section-img {
    flex-direction: column;
}
#section-services .section-img .img {
    width: 100%;
    margin-right: 0;
    padding-bottom: 10px;
}
#section-marques .bloc .left-right .img-responsive {
    margin: auto;
}
#section-filiales .bloc .list {
    padding-left: 10%;
    flex-direction: column;
}
#section-filiales .bloc .list .left-side {
    width: 100%;
}
#section-filiales .bloc .list .right-side {
    width: 100%;
    margin-top: -41px;
}
#section-coordonnees .info-map .left-side .tel .txt {
    width: 48%;
}
#section-services.page-coordonnees .heures-form .heures .jour {
    width: 30%;
}
#section-services.page-coordonnees .heures-form .formulaire .form-group .flex {
    flex-direction: column;
}
#section-services.page-coordonnees .heures-form .formulaire .btn {
    margin-left: 0;
}
#section-coordonnees .poste .bloc {
    width: 100%;
    text-align: center;
}
#section-coordonnees {
    padding: 50px 5%;
}
div#section-realisations .section-projets .col-sm-4 {
    width: 100%;
}
#section-services .background {
    min-height: 440px;
}
#section-list.emploi .list .left-side {
    width: 100%;
    margin-bottom: -10px;
}
#section-emploi .emploi .bloc ul {
    padding-left: 20px;
}
#section-emploi .emploi .bloc .bouton {
    width: 65%;
}
#section-formulaire .form-group .flex {
    flex-direction: column;
}
#section-formulaire form label {
    width: 100%;
}
#section-formulaire textarea#message {
    margin-left: 0;
}
#section-formulaire #right {
    margin-left: 0;
}
#section-services.page-coordonnees .heures-form .left-side {
    width: 100%;
}
#section-services.page-coordonnees .heures-form .left-side .heures .jour {
    width: 30%;
}
}

@media screen and (max-width: 360px) {

nav.module-menu .menu-navbar .wrapper.center .logo {
    max-width: 230px;
}
nav.module-menu .menu-navbar {
    height: 71px;
}
#section-conception .left-side {
    min-height: 315px;
    padding: 50px 5%;
}
}