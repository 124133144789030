.btn {
    background-color: #182548;
    color: $color-white;
    border: none;
    border-radius: $round-corner-radius;
    padding: 15px;
    font-size: $font-size-22;
    font-weight: bold;
    width: 100%;
    transition: all 0.2s ease-in;
    margin-left: 48%;
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
